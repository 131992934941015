import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import { format } from "date-fns";
import {
    ButtonSelect,
    GridCard,
    RangePicker,
} from "../../../../common/components";
import { toast } from "react-toastify";
import { services } from "../../services";
import { useTranslation } from "react-i18next";

import { BranchSales } from "../components/BranchSales";
import { OrderTypes } from "../components/OrderTypes";
import { TrendingHours } from "../components/TrendingHours";
import { ChannelsSales } from "../components/ChannelsSales";
import { InfoCard } from "../components/InfoCard";
import { constants } from "../../../../config/constants";
import { getToken, getUserStorage } from "../../../../common/utils";
import { numberWithCommas } from "../../../../common/utils/numberWithCommas";
import { ExportButton } from "../../../../common/components/Export/Export";

const SalesInsights = () => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const user = getUserStorage();
    const isOwner = user.role === 1;
    const isGroupOwner = user.role === 9;
    const userBranches =
        isOwner && !user.manager_view ? user?.merchant?.branches : user?.branch;
    const userGroups = user?.group;
    const allBranches = userBranches.map((branch) => ({
        id: branch.branch_id,
        label: branch.name,
    }));
    const isManagerView = user.manager_view;
    const allBranchesOption = { id: "all", label: t("common.all_branches") };
    const allGroups =
        userGroups?.map((group) => ({
            id: group.merchant_id,
            label: group.name,
        })) || [];
    const allGroupsOption = { id: "all", label: t("common.all_groups") };
    const [selectedGroup, setSelectedGroup] = useState(allGroupsOption.id);
    const [selectedBranch, setSelectedBranch] = useState(
        isManagerView ? allBranches[0].id : allBranchesOption.id
    );

    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
    const [overviewPDF, setOverviewPDF] = useState(null);
    const [period, setPeriod] = useState({
        startDate: null,
        endDate: format(new Date(), "y-M-d"),
    });
    const [salesData, setSalesData] = useState(null);
    const [orderTypesData, setOrderTypesData] = useState(null);
    const [channelsData, setChannelsData] = useState(null);
    const [branchesSales, setBranchesSales] = useState(null);

    const [trendingHoursData, setTrendingHoursData] = useState([]);

    const { mutate: getSalesInsights, isLoading } = useMutation({
        mutationFn: services.getSalesInsights,
        onSuccess: (res) => {
            if (res.data.response && !res.data.error) {
                setSalesData(res.data.response);

                const refinedTrendingHours = refineTrendingHours(
                    res.data.response.hourly_trending
                );

                const trending_hours = refinedTrendingHours.map((hourData) => ({
                    name: constants.hours[`${hourData.hour_number}`],
                    uv: hourData.total_sales,
                }));

                const orderTypesTotal = getTotalCount(
                    res.data.response.sales_insights_orders
                );
                // const branchSalesTotal = getTotalCount(
                //     res.data.response?.branch_sales
                // );
                const channelsTotal = getTotalCount(
                    res.data.response?.sales_insights_channels
                );

                const branchSalesTotal = getTotalCount(
                    res.data.response?.branch_sales
                );

                // refined with other
                // const branchSales = refineData(
                //     res.data.response.branch_sales.map((branch, index) => ({
                //         name: branch.branch_name,
                //         value: Math.ceil(
                //             ((+branch.total_sales / branchSalesTotal).toFixed(
                //                 4
                //             ) *
                //                 1000) /
                //                 10
                //         ),
                //         sales: numberWithCommas(branch.total_sales),
                //         fill: constants.pieColors[index],
                //         // innerRadius: (index + 1) * 10,
                //         outerRadius: 115 + (index + 1) * 10,
                //     }))
                // );
                const branchSales = res.data.response.branch_sales.map(
                    (branch, index) => ({
                        name: branch.branch_name,
                        value: (
                            ((+branch.total_sales / branchSalesTotal) * 1000) /
                            10
                        ).toFixed(2),
                        sales: numberWithCommas(branch.total_sales),
                        fill: constants.pieColors[index],
                        // innerRadius: (index + 1) * 10,
                        outerRadius: 115 + (index + 1) * 10,
                    })
                );

                const orderTypes_Data =
                    res.data.response.sales_insights_orders.map(
                        (order, index) => ({
                            name: order.order_type,
                            value: (
                                ((order.total_sales / orderTypesTotal) * 1000) /
                                10
                            ).toFixed(2),
                            fill: constants.pieColors[index],
                            innerRadius: (index + 1) * 10,
                            outerRadius: 100 + (index + 1) * 12,
                        })
                    );

                const channels_data =
                    res.data.response.sales_insights_channels.map(
                        (app, index) => ({
                            name: app.app_name,
                            value:
                                Math.floor(
                                    (+app.total_sales / channelsTotal).toFixed(
                                        2
                                    ) * 1000
                                ) / 10,
                            fill: constants.pieColors[index],
                        })
                    );

                setTrendingHoursData(trending_hours);
                setOrderTypesData(orderTypes_Data);
                setChannelsData(channels_data);

                if (isOwner) {
                    setBranchesSales(branchSales);
                }

                setOverviewPDF({
                    sales: res.data.response,
                    channels: channels_data,
                    trending_hours,
                    order_types: orderTypes_Data,
                    branches_sales: branchSales,
                    period,
                });
            }
            if (res.data?.error) {
                toast.error(res.data.message, {
                    hideProgressBar: true,
                });
            }
        },
        onError: (err) => console.log(err),
    });

    const refineTrendingHours = (arr) => {
        const refined = [];
        for (let i = 0; i < 24; i++) {
            const hour = arr.find((item) => i === +item.hour_number);
            if (hour) refined.push(hour);
            else refined.push({ hour_number: i.toString(), total_sales: 0 });
        }

        return refined;
    };

    const handleDatesChange = ({ startDate, endDate }) => {
        if (startDate !== "1970-1-1") {
            setPeriod({ startDate, endDate });

            getSalesInsights({
                from_date: startDate,
                till_date: endDate,
                [`${isGroupOwner ? "merchant_filter" : "branch_id"}`]:
                    isGroupOwner ? selectedGroup : selectedBranch,
            });
        }
    };

    const handleBranchChange = (e) => {
        const newSelectedBranchValue = e.target.value;
        setSelectedBranch(newSelectedBranchValue);
        getSalesInsights({
            from_date: period.startDate,
            till_date: period.endDate,
            branch_id: newSelectedBranchValue,
        });
    };

    const handleGroupChange = (e) => {
        const newSelectedGroupValue = e.target.value;
        setSelectedGroup(newSelectedGroupValue);
        getSalesInsights({
            from_date: period.startDate,
            till_date: period.endDate,
            merchant_filter: newSelectedGroupValue,
        });
    };

    useEffect(() => {
        if (period.startDate) {
            getSalesInsights({
                from_date: new Date("1/1/2000").toLocaleDateString("en"),
                till_date: format(new Date(), "y-M-d"),
                [`${isGroupOwner ? "merchant_filter" : "branch_id"}`]:
                    isGroupOwner ? selectedGroup : selectedBranch,
            });
            getSalesInsights({
                from_date: new Date("1/1/2000").toLocaleDateString("en"),
                till_date: format(new Date(), "y-M-d"),
            });
        }
    }, []);

    function getTotalCount(arr, key = "total_sales") {
        let count = 0;
        arr.forEach((i) => (count = count + +i[key]));

        return count;
    }

    const refineData = (dataArr) => {
        if (!dataArr) return;

        const mainChartData = [];
        const otherChartData = [];

        dataArr.forEach((item) => {
            if (item.value > 15) mainChartData.push({ ...item });
            else otherChartData.push(item);
        });

        let totalOtherValue = 0;
        let otherString = `\nOther \n `;
        if (otherChartData.length) {
            otherChartData.forEach((otherItem, i) => {
                totalOtherValue = totalOtherValue + otherItem.value;
                otherString =
                    otherString +
                    `- ${otherItem.name} (${otherItem.value}%) \n`;
            });
        }

        if (otherChartData.length)
            return [
                ...mainChartData,
                {
                    ...otherChartData[0],
                    name: "Other",
                    label: otherString,
                    value: totalOtherValue,
                },
            ].map((item, index) => ({
                ...item,
                fill: constants.pieColors[index],
            }));

        return mainChartData;
    };

    useEffect(() => {
        window.pendo.pageLoad({
            name: "Sales Insights Page",
        });
    }, []);

    return (
        <Stack padding={8} spacing={8} minWidth="100%">
            <Typography variant="h5">{t("sales.insights")}</Typography>

            <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" alignItems="center" gap={3}>
                    <RangePicker
                        onChange={(value) => handleDatesChange(value)}
                    />

                    {isLoading && (
                        <CircularProgress color="primary" size={20} />
                    )}
                </Box>

                <Box display="flex" alignItems="center" gap={3}>
                    {!isManagerView && !isGroupOwner && (
                        <ButtonSelect
                            value={selectedBranch}
                            onChange={handleBranchChange}
                            options={[allBranchesOption, ...allBranches]}
                        />
                    )}

                    {isGroupOwner && (
                        <ButtonSelect
                            value={selectedGroup}
                            onChange={handleGroupChange}
                            options={[allGroupsOption, ...allGroups]}
                        />
                    )}

                    <ExportButton
                        overviewPDF={overviewPDF}
                        pageName="salesInsightsPDF"
                        isLoading={isLoading}
                    />
                </Box>
            </Box>

            <Grid container spacing={0} flexWrap="nowrap">
                <Grid
                    item
                    xs={3}
                    marginRight={isArabic ? 0 : 6}
                    marginLeft={isArabic ? 6 : 0}
                >
                    <InfoCard
                        iconPath="/ic_sales_outline.svg"
                        label={t("sales.total_sales")}
                        value={`${
                            numberWithCommas(
                                salesData?.sales_insights_totals[0]?.total_sales
                            ) || 0
                        } ${t("common.currency")} `}
                        tooltipLabel={t("tooltips.sales_total_sales")}
                    />
                </Grid>

                <Grid
                    item
                    xs={3}
                    marginRight={isArabic ? 0 : 6}
                    marginLeft={isArabic ? 6 : 0}
                >
                    <InfoCard
                        iconPath="/ic_sales_outline.svg"
                        label={t("sales.total_profitability")}
                        value={`${
                            numberWithCommas(
                                salesData?.sales_insights_totals[0]
                                    ?.total_profitability
                            ) || 0
                        } ${t("common.currency")} `}
                        tooltipLabel={t("tooltips.sales_total_profitability")}
                    />
                </Grid>
                <Grid
                    item
                    xs={3}
                    marginRight={isArabic ? 0 : 6}
                    marginLeft={isArabic ? 6 : 0}
                >
                    <InfoCard
                        iconPath="/ic_transactions_outline.svg"
                        label={t("sales.total_transactions")}
                        value={`${
                            salesData?.sales_insights_totals[0]
                                ?.total_transactions || 0
                        }`}
                        tooltipLabel={t("tooltips.sales_total_transactions")}
                    />
                </Grid>

                <Grid item xs={3} marginRight={0} marginLeft={0}>
                    <InfoCard
                        iconPath="/products.svg"
                        label={t("sales.total_products")}
                        value={`${
                            salesData?.sales_insights_totals[0]
                                ?.total_product || 0
                        } ${t("common.product_2")}`}
                        tooltipLabel={t("tooltips.sales_total_products")}
                    />
                </Grid>
            </Grid>

            <Grid
                container
                spacing={6}
                style={{
                    margin: isArabic ? 0 : "-24px",
                    marginTop: 0,
                    marginBottom: 0,
                }}
                sx={{
                    minHeight: 400,
                    height: "fit-content",
                }}
            >
                <GridCard
                    gridProps={{ xs: 12, md: 6 }}
                    padding={0}
                    height="inherit"
                >
                    {isOwner ? (
                        <BranchSales data={branchesSales} />
                    ) : (
                        <OrderTypes data={orderTypesData} />
                    )}
                </GridCard>

                <GridCard
                    gridProps={{ xs: 12, md: 6 }}
                    // gutter
                    padding={0}
                    height="inherit"
                >
                    <ChannelsSales data={channelsData} />
                </GridCard>
            </Grid>

            <Grid container spacing={0} flexWrap="nowrap">
                <Grid
                    item
                    xs={6}
                    marginRight={isArabic ? 0 : 6}
                    marginLeft={isArabic ? 6 : 0}
                >
                    <Stack spacing={6}>
                        <InfoCard
                            iconPath="/ic_cart_outline.svg"
                            containerStyles={{
                                height: 157,
                            }}
                            label={t("sales.avg_basket_value")}
                            value={`${
                                numberWithCommas(
                                    salesData?.sales_insights_totals[0]
                                        ?.avg_basket_size_value
                                ) || 0
                            } ${t("common.currency")} `}
                            tooltipLabel={t(
                                "tooltips.customer_avg_basket_value"
                            )}
                        />

                        <InfoCard
                            iconPath="/ic_quantity.svg"
                            containerStyles={{
                                height: 157,
                            }}
                            label={t("sales.avg_basket_quantity")}
                            value={`${
                                salesData?.sales_insights_totals[0]
                                    ?.avg_basket_size_quantity || 0
                            } ${t("common.products_clean")}`}
                            tooltipLabel={t(
                                "tooltips.customer_avg_basket_size"
                            )}
                        />
                    </Stack>
                </Grid>

                <GridCard padding={0} height={338}>
                    <OrderTypes
                        data={orderTypesData}
                        pieContainerStyles={{ top: "-12px" }}
                    />
                </GridCard>
            </Grid>

            <Grid container spacing={0} flexWrap="nowrap">
                <GridCard fullWidth padding={0}>
                    <TrendingHours data={trendingHoursData} />
                </GridCard>
            </Grid>

            {/* This is changed because now the branch manager could have many branches */}
            {/* {!isOwner && (
                <Grid container spacing={0} flexWrap="nowrap">
                    <GridCard gutter>
                        <OrderTypes data={orderTypesData} />
                    </GridCard>

                    <Grid item xs={6} />
                </Grid>
            )} */}
        </Stack>
    );
};

export { SalesInsights };
